<template>
  <div class="container">
    <img src="../../assets/activity_h5bg.png" alt="" class="activity_bg" />
    <div class="activity">
      <img src="../../assets/activity_h5.png" alt="" @click="gotoJD" />
    </div>
    <div class="box" v-if="show">
      <img src="../../assets/activity_h5box.png" alt="" />
    </div>
  </div>
</template>

<script>
// import 'lib-flexible'

import { resetRem } from "@/assets/rem.js";
export default {
  data() {
    return {
      show: false,
    };
  },
  created() {
    document.title = "京东618主会场";
    resetRem();
  },
  mounted() { this.gotoJDOnload()},
  methods: {
    gotoJDOnload(){
      let viewer = window.navigator.userAgent.toLowerCase();
      var p = navigator.platform;
      var system = {};
      system.win = p.indexOf("Win") == 0;
      system.mac = p.indexOf("Mac") == 0;
      system.x11 = p == "X11" || p.indexOf("Linux") == 0;
      if (system.win || system.mac || system.xll) {
        //如果是电脑跳转到
        window.location.href = "https://u.jd.com/zdrovDe";
      } else {
        //如果是手机,跳转到
        if (viewer.match(/MicroMessenger/i) != "micromessenger") {
          window.location.href = "https://u.jd.com/zdrovDe";
        }
      }
    },
    gotoJD() {
      let viewer = window.navigator.userAgent.toLowerCase();
      var p = navigator.platform;
      var system = {};
      system.win = p.indexOf("Win") == 0;
      system.mac = p.indexOf("Mac") == 0;
      system.x11 = p == "X11" || p.indexOf("Linux") == 0;
      if (system.win || system.mac || system.xll) {
        //如果是电脑跳转到
        window.location.href = "https://u.jd.com/zdrovDe";
      } else {
        //如果是手机,跳转到
        if (viewer.match(/MicroMessenger/i) == "micromessenger") {
          this.show = true;
        } else {
          window.location.href = "https://u.jd.com/zdrovDe";
        }
      }
    },
  },
};
</script>
<style scoped>
.container {
  width: 10rem;
  margin: 0 auto;
  height: 100vh;
  overflow: hidden;
  position: relative;
}
.activity_bg {
  width: 100%;
}
.activity {
  width: 10rem;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 50%;
  margin-left: -5rem;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.activity img {
  width: 10rem;
}
.box {
  position: fixed;
  top: 0;
  right: 0;
}
.box img {
  width: 10rem;
}
</style>